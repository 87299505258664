import { HTMLAttributes, useEffect, useState } from 'react';
import { useOrderState } from '/store/orderStore';
import PostCodeCheckerModal from '/components/features/post-code-checker-modal/PostCodeCheckerModal';
import PostCodeButtonPure from './PostCodeButton.pure';
import { useHydration } from '/store/store.helpers';
import postCodeApi from '/api/postCode';
import { JobTemplateType } from '/types/jobTemplateTypes';
import { useRouter } from 'next/router';
import useCartReservation from '/hooks/useCartReservation';
import { goTo } from '/lib/helpers';

interface Props extends HTMLAttributes<HTMLDivElement> {
    withPlaceName?: boolean;
    jobType?: JobTemplateType;
}

export default function PostCodeStatusButton({
    withPlaceName,
    jobType,
    ...rest
}: Props) {
    const postCode = useOrderState((state) => state.postCode);
    const postCodePlace = useOrderState((state) => state.postCodePlace);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const postCodeValid = useOrderState((state) => state.postCodeValid);
    const postCodeSupportedJobTypes = useOrderState(
        (state) => state.postCodeSupportedJobTypes
    );
    const setPostCodePlace = useOrderState((state) => state.setPostCodePlace);
    const setOrderPostCodeSupportedJobTypes = useOrderState(
        (state) => state.setOrderPostCodeSupportedJobTypes
    );
    const setOrderPostCodeValidity = useOrderState(
        (state) => state.setOrderPostCodeValidity
    );
    const hydrated = useHydration();
    const router = useRouter();

    const { clearCartReservation, setCartReservationUuid } =
        useCartReservation();

    useEffect(() => {
        if (
            hydrated &&
            postCode &&
            postCodeValid &&
            postCodeSupportedJobTypes.length === 0
        ) {
            /*
            for users that have valid post cde in local storage BUT
            dont have postCodeSupportedJobTypes set (defaults to [])
            we need to recheck the post code
            */
            (async () => {
                const response = await postCodeApi.checkPostCode(postCode);
                if ('error' in response) {
                    setOrderPostCodeValidity(false);
                    setOrderPostCodeSupportedJobTypes([]);
                    setPostCodePlace('');
                } else {
                    setOrderPostCodeValidity(true);
                    setOrderPostCodeSupportedJobTypes(
                        response.data.jobTypes || []
                    );
                    setPostCodePlace(response.data.place);
                }
            })();
        }
    }, [hydrated]);

    if (!postCode) return null;

    return (
        <>
            <PostCodeButtonPure
                postCode={postCode}
                postCodePlace={withPlaceName ? postCodePlace : undefined}
                onClick={() => {
                    if (
                        ['/order/checkout', '/order/confirm'].includes(
                            router.pathname
                        )
                    ) {
                        clearCartReservation();
                        goTo('/bestilling/handlekurv?change-code');
                    } else {
                        setModalVisible(true);
                    }
                }}
                {...rest}
            />
            {modalVisible && (
                <PostCodeCheckerModal
                    closeRequestCallback={() => setModalVisible(false)}
                    cartContext={router.pathname === '/order/cart'}
                    jobTypeContext={jobType}
                />
            )}
        </>
    );
}
